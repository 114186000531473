/* https://uiball.com/ldrs/ */

.dotpulse-container {
  --uib-size: 43px;
  --uib-color: rgb(59, 130, 246);
  --uib-speed: 1.3s;
  --uib-dot-size: calc(var(--uib-size) * 0.24);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: var(--uib-dot-size);
  width: var(--uib-size);
}
.dotpulse,
.dotpulse-container::before,
.dotpulse-container::after {
  content: '';
  display: block;
  height: var(--uib-dot-size);
  width: var(--uib-dot-size);
  border-radius: 50%;
  background-color: var(--uib-color);
  transform: scale(0);
  transition: background-color 0.3s ease;
}
.dotpulse-container::before {
  animation: pulse var(--uib-speed) ease-in-out calc(var(--uib-speed) * -0.375)
    infinite;
}
.dotpulse {
  animation: pulse var(--uib-speed) ease-in-out calc(var(--uib-speed) * -0.25)
    infinite both;
}
.dotpulse-container::after {
  animation: pulse var(--uib-speed) ease-in-out calc(var(--uib-speed) * -0.125)
    infinite;
}
@keyframes pulse {
  0%,
  100% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
}
