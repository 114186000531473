/* @import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities"; */

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  img {
    @apply inline-block;
  }
  .btn {
    @apply bg-blue-500 text-white px-3 py-2 rounded-md disabled:opacity-50;
  }
}

body {
  /* margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.section {
  @apply border rounded-md border-gray-300 p-[10px] text-blueGray-600;
}
